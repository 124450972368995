import Vue from "vue"
import Axios from "axios"
import joinUrl from "url-join"
import { setMany, createStore, get } from "idb-keyval"
import { ActionContext } from "vuex"

import {
  LOCALISATION_STATUS,
  FALLBACK_LOCALE
} from "@shared/localisation-utils"
import { Localisation } from "@shared/types"

const DB_NAME = "movement.so-i18n-db"

const translationsStore = createStore(DB_NAME, "i18n-cache")
const cacheKey = (locale: string, key: string) => `${locale}.${key}`

// In-memory tracking of keys that have been loaded from cache
const MAX_CACHE_SIZE = 250
const memoryCache = new Set<string>()

export const clearMemoryCache = () => memoryCache.clear()

interface IState {
  translations: { [key: string]: string }
}

const state: IState = {
  translations: {}
}

const actions = {
  async fetchTranslations(
    { commit, getters }: ActionContext<IState, any>,
    {
      locale = getters["currentLocale"],
      path
    }: { locale: string; path: string }
  ) {
    // Don't fetch if localisation is not enabled
    if (!getters.isLocalisationEnabled) return Promise.resolve()

    // Don't fetch if locale matches the default locale
    if (getters.defaultLocale === locale) {
      return Promise.resolve()
    }

    const url = joinUrl(
      process.env.VUE_APP_APP_DATA_PATH || "",
      path === "/" ? "" : path,
      `${locale}.json?ts=${+new Date()}`
    )

    return Axios.get(url)
      .then(({ data }) => {
        Object.keys(data).forEach((key: string): void => {
          commit("setTranslation", { key, data: data[key] })
        })

        setMany(
          Object.entries(data).map(([key, value]) => [
            cacheKey(locale, key),
            value
          ]),
          translationsStore
        )
      })
      .catch(error => {
        console.error("Failed to fetch translations:", error)
      })
  },

  async loadFromCache(
    { commit }: ActionContext<IState, any>,
    { key, locale }: { key: string; locale: string }
  ) {
    const fullCacheKey = cacheKey(locale, key)

    // Skip if we've already loaded this key from cache
    if (memoryCache.has(fullCacheKey)) {
      return
    }

    // Add to memory cache before getting from cache as race conditions can occur
    // and we can make multiple calls to the cache
    memoryCache.add(fullCacheKey)

    const data = await get(fullCacheKey, translationsStore)

    if (data) {
      commit("setTranslation", { key, data })
    }

    // Manage cache size before adding new entry
    if (memoryCache.size >= MAX_CACHE_SIZE) {
      // Remove oldest entry (first item in set)
      memoryCache.delete(memoryCache.values().next().value || "")
    }
  }
}

const mutations = {
  setTranslation(state: IState, { key, data }: { key: string; data: object }) {
    Vue.set(state.translations, key, data)
  },

  reset(state: IState) {
    Object.assign(state, {
      translations: {}
    })
  }
}

const getters = {
  getTranslation(state: IState) {
    return (key: string) => {
      return state.translations[key]
    }
  },

  isLocalisationEnabled(_: any, getters: any) {
    return !!getters.availableLocalisations.find(
      (localisation: Localisation) =>
        localisation.status === LOCALISATION_STATUS.PUBLISHED
    )
  },

  availableLocalisations(
    _: any,
    __: any,
    ___: any,
    rootGetters: any
  ): Localisation[] {
    return rootGetters["app"]?.localisations || []
  },

  getLocalisation(_: any, getters: any) {
    return (locale: string): Localisation | null =>
      getters.availableLocalisations.find(
        (localisation: Localisation) => localisation.locale === locale
      )
  },

  defaultLocalisation(_: any, getters: any): Localisation | null {
    return getters.availableLocalisations.find(
      (localisation: Localisation) =>
        localisation.status === LOCALISATION_STATUS.DEFAULT
    )
  },

  defaultLocale(_: any, getters: any, rootState: any): string {
    return (
      getters.defaultLocalisation?.locale ||
      rootState.global?.app?.language ||
      FALLBACK_LOCALE
    )
  },

  currentLocale(_: any, getters: any, rootState: any): string {
    return rootState.auth?.user?.language || getters.defaultLocale
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

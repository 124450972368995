<template>
  <div
    v-if="!isFullscreen"
    :data-role="fixed ? 'fixed-toolbar' : 'inline-toolbar'"
    :data-transparent-toolbar="isBgTransparent"
    class="v-toolbar__wrapper"
  >
    <div
      :class="[
        {
          '--transparent': isBgTransparent,
          '--dark': isDarkBackground,
          '--flat': flat,
          'p-fixed top-0 left-0': fixed
        },
        'v-toolbar'
      ]"
      id="toolbar"
      :style="
        useAccountStyles
          ? { backgroundColor: toolbar.background_color, color: textColor }
          : {}
      "
    >
      <div class="v-toolbar__content container">
        <div class="v-toolbar__left">
          <div
            v-if="back"
            @click="attemptBack"
            class="v-toolbar__button"
            :style="useAccountStyles ? { color: textColor } : {}"
            data-cy="back"
            data-role="back"
          >
            <v-icon icon="arrow_back" rtl-flip />
          </div>

          <div
            v-if="withMenu && !isDesktop"
            @click="toggleSidebar"
            class="v-toolbar__button"
            :style="useAccountStyles ? { color: textColor } : {}"
            data-cy="menu"
          >
            <v-icon icon="menu" />
          </div>

          <slot name="left" />

          <template v-if="!hasSlotData && alignment === 'left'">
            <toolbar-logo
              v-if="logo"
              :logo="toolbar.logo"
              :fallback-text="app.name"
              :styles="styles"
            />
          </template>
        </div>

        <div class="v-toolbar__center">
          <template v-if="!hasSlotData && alignment === 'center'">
            <toolbar-logo
              v-if="logo"
              :logo="toolbar.logo"
              :fallback-text="app.name"
              :styles="styles"
            />
          </template>

          <slot name="center" />
        </div>

        <div class="v-toolbar__right">
          <slot name="right" :text-color="textColor" />
        </div>
      </div>

      <slot name="append" />
    </div>

    <div
      v-if="fixed && !transparent && !hideSpacer"
      class="v-toolbar__spacer"
    ></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"

import enumValidator from "@/lib/validators/enum-validator"
import determineTextColor, { isDarkTextColor } from "@/lib/determine-text-color"
import { withRatio } from "@shared/block-ratio"
import debounce from "@/lib/debounce"

import ToolbarLogo, { DEFAULT_STYLES } from "@/components/toolbar/Logo"
import { getScrollRootElement } from "@/lib/page-utils"

export default {
  components: {
    ToolbarLogo
  },

  props: {
    transparent: { type: Boolean, default: false },
    animateBgToWhite: { type: Boolean, default: false },
    logo: { type: Boolean, default: true },
    fixed: { type: Boolean, default: false },
    back: { type: Boolean, default: true },
    align: {
      type: String,
      default: null,
      validator: enumValidator(["left", "center"])
    },
    emitOnExitAttempt: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    useAccountStyles: { type: Boolean, default: false },
    withMenu: { type: Boolean, default: false }
  },

  data() {
    return {
      hadScrolled: false
    }
  },

  computed: {
    ...mapState(["global"]),
    ...mapState("videos", ["isFullscreen"]),
    ...mapState("responsive", ["isDesktop"]),

    app() {
      return this.global?.app || {}
    },

    alignment() {
      return this.align ? this.align : this.toolbar?.align
    },

    isDarkBackground() {
      return (
        !this.isBgTransparent &&
        this.useAccountStyles &&
        !isDarkTextColor(this.toolbar?.background_color)
      )
    },

    toolbar() {
      return this.global?.toolbar || {}
    },

    textColor() {
      return determineTextColor(this.toolbar.background_color, {
        black: "#384250",
        white: "#ffffff"
      })
    },

    styles() {
      return {
        ...DEFAULT_STYLES,
        height: withRatio(50, this.toolbar.logo_height_ratio, { unit: "%" })
      }
    },

    hasSlotData() {
      return !!this.$slots.left || !!this.$slots.center
    },

    isBgTransparent() {
      return this.shouldAnimateBg ? !this.hadScrolled : this.transparent
    },

    hideSpacer() {
      return this.animateBgToWhite && !this.hadScrolled
    },

    shouldAnimateBg() {
      return this.transparent && this.animateBgToWhite
    }
  },

  methods: {
    ...mapMutations("tabbar", ["toggleSidebar"]),

    goBack() {
      this.$router.back()
    },

    attemptBack() {
      if (this.emitOnExitAttempt) {
        this.$emit("exitAttempted")
      } else {
        this.goBack()
      }
    },

    handleScroll() {
      debounce(this.toggleScroll, 50)
    },

    toggleScroll() {
      const rootElement = getScrollRootElement()
      if (!rootElement) return

      const genericPage = rootElement.querySelector(".generic-page")
      const toolbar = document.querySelector("#toolbar")

      if (genericPage && toolbar) {
        const top = genericPage.getBoundingClientRect().top

        const toolbarHeight = toolbar.getBoundingClientRect().height
        this.hadScrolled = !!(top < -toolbarHeight)
      }
    }
  },

  beforeDestroy() {
    if (this.shouldAnimateBg) {
      getScrollRootElement().removeEventListener("scroll", this.handleScroll)
    }
  },

  mounted() {
    if (this.shouldAnimateBg) {
      getScrollRootElement().addEventListener("scroll", this.handleScroll)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.v-toolbar {
  background-clip: padding-box;
  user-select: none;
  position: relative;
  z-index: $toolbar--z-index;
  color: $color-ui--grey-90;
  font-weight: $font-weight--base;
  width: 100%;
  border: none;
  box-shadow: $box-shadow--medium;
  background-color: rgba($color-rgb--white, 0.86);
  backdrop-filter: blur(6px);
  padding-top: env(safe-area-inset-top);
  transition: background-color 0.25s ease;

  &.--flat {
    box-shadow: none;
  }

  &.--transparent {
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;

    .v-toolbar__button {
      @include clickable(0.9);

      background: $color-ui--white;
      position: relative;
      left: -$size--2;
      top: $size--1;
      box-shadow: $box-shadow--medium;
      color: $color-ui--grey-70;
      border-radius: 50%;
      width: $size--9;
      height: $size--9;
      padding: 0 $size--4;

      &:last-child {
        margin-right: 0;
      }

      .v-loader {
        height: 1.5rem;
        width: 1.5rem;
        min-height: 1.5rem;
        min-width: 1.5rem;
        max-height: 1.5rem;
        max-width: 1.5rem;
      }
    }

    .v-toolbar__left {
      .v-toolbar__button {
        margin-right: $size--2;
      }

      .v-toolbar__button:first-child {
        margin-left: 0;
      }
    }

    .v-toolbar__right {
      .v-toolbar__button {
        background: rgba($color-rgb--black, 0.2);
        color: $color-ui--white;
        box-shadow: none;
        margin-left: $size--2;
      }
    }
  }

  &:not(.--transparent) {
    .v-toolbar__left {
      .v-toolbar__button {
        padding-right: 0;
      }

      .v-toolbar__button:last-child {
        padding-right: $size--5;
      }

      .v-toolbar-logo,
      .v-toolbar__text {
        margin-left: $size--5;
      }
    }

    // TODO: Come up with a better approach for this...
    // Something to do with standard "active" state and re-use class names
    .bookmark-button {
      opacity: 0.5;
    }

    .bookmark-button.--bookmarked {
      opacity: 1;
      box-shadow: none !important;
    }

    .notification-button.--on {
      box-shadow: none !important;
    }

    .v-toolbar__button.--reduced {
      font-size: $font-size--large !important;
      color: $color-ui--grey-60 !important;
    }

    #toolbar-submission-button-portal .v-toolbar__button {
      margin-right: 0 !important;
    }
  }
}

.--dark-mode {
  .v-toolbar {
    &.--transparent {
      .v-toolbar__button {
        border: 2px solid white;
      }

      .v-toolbar__right {
        .v-toolbar__button {
          border: none;
          color: white;

          &.--on {
            border: 2px solid white;
            background: black;
          }
        }
      }
    }
  }
}

.v-toolbar__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: $toolbar--height;
  white-space: nowrap;

  & > div {
    min-height: $toolbar--height;
    display: flex;
    align-items: center;
  }

  .v-toolbar__left,
  .v-toolbar__right {
    flex: 0 1 27%;
  }

  .v-toolbar__left {
    padding-left: $size--4;
    justify-content: flex-start;
    max-width: 80%;

    .v-toolbar__button:first-child {
      margin-left: -$size--4;
    }

    .v-toolbar-logo {
      margin-left: $size--2;
    }
  }

  .v-toolbar__center {
    flex: 1 2 46%;
    align-items: center;
    justify-content: center;
  }

  .v-toolbar__right {
    display: flex;
    justify-content: flex-end;
  }

  .v-toolbar__button {
    font-size: 1.35rem;
    color: $color-ui--grey-70;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $size--5;
    height: 100%;
    margin: 0;
    border: none;
    border-radius: 0;
  }

  .v-toolbar__text {
    @include overflow-ellipsis;

    font-weight: $font-weight--bold;
    font-size: 1.35rem;
  }
}

.v-toolbar__spacer {
  height: calc(#{$toolbar--height} + env(safe-area-inset-top));
  background: $color-ui--white;
}

.v-toolbar__button {
  @include clickable(0.9);
}

[dir="rtl"] {
  .v-toolbar {
    &:not(.--transparent) {
      .v-toolbar-logo,
      .v-toolbar__text {
        margin-right: $size--5;
        margin-left: 0;
      }
    }
  }
}
</style>

export const stringToHtml = (content: string): Element | null => {
  try {
    return new DOMParser()
      .parseFromString(content, "text/html")
      .documentElement.querySelector("body");
  } catch {
    return null;
  }
};

export const replaceTextInHtml = (content: string, replacedValue: string) => {
  const body = stringToHtml(content);

  if (!body) return content;

  const node = body.childNodes[0] as Element;

  if (!node) return content.replace("></", `>${replacedValue}</`);
  if (!node.outerHTML) return content.replace("></", `>${replacedValue}</`);

  const replaceWithHtml = !!node.innerHTML
    ? node.outerHTML.replace(node.innerHTML.trim(), replacedValue)
    : (node as any).outerHTML.replace("></", `>${replacedValue}</`);

  return node ? body.innerHTML?.replace(node.outerHTML, replaceWithHtml) : "";
};

// TODO: SPec
export const isMultiLine = (text: string): boolean => {
  return (
    (new DOMParser()
      .parseFromString(text, "text/html")
      .documentElement?.querySelector("body")?.childNodes?.length || 0) > 1
  );
};

// TODO: Update
export const isHtml = (text: string | null): boolean => {
  return String(text)?.startsWith("<") ?? false;
};

export const innerText = (html: string): string => {
  return (
    new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement?.querySelector("body")?.textContent || ""
  );
};

export const hasInnerFormatting = (html: string | number): boolean => {
  // Return false for non-string inputs or non-HTML content
  if (typeof html !== "string" || !html.startsWith("<")) {
    return false;
  }

  // Single-pass implementation that checks for all cases
  let i = 0;
  let inTag = false;
  let foundClosingTag = false;

  while (i < html.length) {
    // Opening bracket
    if (html[i] === "<") {
      // Check for closing tag
      if (html[i + 1] === "/") {
        foundClosingTag = true;
      }
      inTag = true;
    }
    // Closing bracket
    else if (html[i] === ">") {
      inTag = false;
    }
    // Text content (not in a tag)
    else if (!inTag) {
      // If we have non-whitespace text
      if (html[i].trim()) {
        // Case 1: If we've already seen a closing tag and now found text, it's inner formatting
        if (foundClosingTag) {
          return true;
        }

        // Case 2: If text is followed by an opening tag (not a closing tag)
        const nextOpeningBracket = html.indexOf("<", i);
        if (nextOpeningBracket !== -1 && html[nextOpeningBracket + 1] !== "/") {
          return true;
        }
      }
    }
    i++;
  }

  // No inner formatting detected
  return false;
};

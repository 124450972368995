<template>
  <a
    :class="[{ '--active': isActive }, 'v-link']"
    :href="pagePath"
    ref="link"
    @click="handleClick"
  >
    <template v-if="withAccessChecks">
      <slot
        :is-loading-access="forceLock ? false : isLoadingAccess"
        :is-locked="forceLock || isLocked"
        :available-after="
          isLockedByDrip && pageAccess.data
            ? pageAccess.data.available_after
            : null
        "
      ></slot>
    </template>
    <template v-else>
      <slot
        :is-loading-access="false"
        :is-locked="false"
        :available-after="null"
      ></slot>
    </template>
  </a>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

import isBuilderContext from "@/lib/is-platform/is-builder-context"
import fetchPageData from "@/lib/fetch-page-data"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { getPageAccess, REASONS } from "@/lib/access-utils"
import { SW_UPDATE_QUERY_PARAM, MANAGED_PAGE_PREFIX } from "@/lib/constants"
import { getPathFromUrl, urlOriginCompare } from "@/lib/url-helpers"
import { trackGTMLinkClick } from "@/lib/gtm-utils"

export default {
  props: {
    to: { type: String, default: null },
    replace: { type: Boolean, default: false },
    withAccessChecks: { type: Boolean, default: false },
    pageId: { type: [String, Number], default: null },
    parentPageId: { type: [String, Number], default: null }, // Rename to currentPageId
    forceLock: { type: Boolean, default: false },
    isAttachment: { type: Boolean, default: false }
  },

  data() {
    return {
      isLoadingAccess: true,

      hasLoadedData: false,
      observer: null,

      isUpdateAvailable: false,
      page: null
    }
  },

  computed: {
    ...mapState("pages", ["pageSubscriptions", "pageProgress"]),
    ...mapGetters(["appAccess"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("messages", ["getMessageThread"]),

    isLocked() {
      return !this.pageAccess.granted
    },

    pageAccess() {
      if (isBuilderContext()) return { granted: true }
      if (!this.isMessageThreadLink && this.to?.startsWith(MANAGED_PAGE_PREFIX))
        return { granted: true }
      if (this.isLoadingAccess || !this.hasLoadedData) return { granted: false }

      return getPageAccess({
        pageId: this.pageId,
        currentPageId: this.parentPageId,
        currentUser: this.currentUser,
        appAccess: this.appAccess,
        pageSubscriptions: this.pageSubscriptions,
        pageProgresses: this.pageProgress,
        forcePreventBrowsing: this.isAttachment || this.isMessageThreadLink
      })
    },

    shouldLoadData() {
      return (
        this.isMessageThreadLink ||
        (this.to &&
          this.to.startsWith("/") &&
          !this.to.startsWith(MANAGED_PAGE_PREFIX))
      )
    },

    isMessageThreadLink() {
      return (
        !!this.to &&
        this.to?.startsWith(`${MANAGED_PAGE_PREFIX}messages/`) &&
        this.pageId
      )
    },

    isLockedByRequirePurchase() {
      return (
        this.isLocked && this.pageAccess.reason === REASONS.REQUIRE_PURCHASE
      )
    },

    isLockedByRequireAuth() {
      return (
        this.isLocked &&
        this.pageAccess.reason === REASONS.REQUIRE_AUTHENTICATION
      )
    },

    isLockedByDrip() {
      return this.isLocked && this.pageAccess.reason === REASONS.IS_SCHEDULED
    },

    pagePath() {
      if (this.forceLock) return ""
      if (this.isLoadingAccess) return ""

      if (this.isLockedByRequireAuth) return "#auth"
      if (this.isLocked) return ""

      return this.to
    },

    isActive() {
      return this.$route.path === this.to
    }
  },

  methods: {
    ...mapActions("pages", ["loadAccess"]),
    ...mapActions("localisation", ["fetchTranslations"]),

    handleClick(e) {
      e.preventDefault()

      trackGTMLinkClick(this.linkTarget(), this.linkText())

      if (this.withAccessChecks) {
        if (this.forceLock) return

        if (this.isLockedByRequirePurchase) {
          // Trigger Purchase
          EventBus.$emit(EVENTS.TRIGGER_CHECKOUT, {
            productIds: this.pageAccess.data.product_ids,
            overlay: this.pageAccess.data.purchase_overlay
          })
        } else if (this.isLockedByDrip) {
          if (this.pageAccess.data) {
            // Trigger Drip Modal
            EventBus.$emit(
              EVENTS.TRIGGER_SCHEDULED_MODAL,
              this.pageAccess.data.available_after
            )
          }
        } else if (this.isLockedByRequireAuth) {
          // Trigger Auth Modal
          this.goToPath("#auth")
        } else if (this.isLocked) {
          // Do nothing
          // TODO: Show something for private
          return
        } else if (this.$listeners.click) {
          this.$listeners.click()
        } else {
          this.goToPath(this.to)
        }
      } else {
        if (this.$listeners.click) {
          this.$listeners.click()
        } else {
          // The argument linkTarget can be either a full URL (for external links)
          // or a relative path (for internal navigation), based on the href attribute of the link
          this.goToPath(this.linkTarget())
        }
      }
    },

    linkText() {
      return this.$refs["link"]?.innerText || ""
    },

    linkTarget() {
      if (this.forceLock || this.isLocked) return this.to

      const linkHref = this.$refs["link"].href

      return urlOriginCompare(linkHref, window.location.href)
        ? getPathFromUrl(linkHref, true) // internal path
        : linkHref // external URL
    },

    goToPath(path) {
      if (this.isUpdateAvailable) {
        EventBus.$emit(EVENTS.SHOW_GLOBAL_LOADER)
        window.location.href = path + `?${SW_UPDATE_QUERY_PARAM}`
        setTimeout(() => {
          // This ensures the global loader is hidden even if someone on iOS
          // swipes to navigate back to the page they came from
          EventBus.$emit(EVENTS.HIDE_GLOBAL_LOADER)
        }, 1500)
      } else if (path?.startsWith("http")) {
        window.open(path, "_blank")
      } else if (path === "/") {
        this.$router.goHome()
      } else if (this.replace) {
        this.$router.replace(path)
      } else {
        this.$router.push(path)
      }
    },

    handleSwUpdateAvailable() {
      this.isUpdateAvailable = true
    },

    async loadData() {
      this.isLoadingAccess = true

      try {
        if (this.isMessageThreadLink) {
          await this.loadAccess({ pageId: this.pageId })

          return
        }
        // Prefetch the page data, and then load the access tree
        const data = await fetchPageData(this.to)
        const pageId = data?.props?.page_id

        this.fetchTranslations({ path: this.to })

        if (pageId) {
          await this.loadAccess({ pageId })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingAccess = false
      }
    }
  },

  mounted() {
    if (this.shouldLoadData) {
      if ("IntersectionObserver" in window) {
        this.observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            if (!this.hasLoadedData) {
              this.loadData()

              this.hasLoadedData = true
            }
          }
        })

        this.observer.observe(this.$refs["link"])
      } else {
        this.loadData()
        this.hasLoadedData = true
      }
    } else {
      this.hasLoadedData = true
      this.isLoadingAccess = false
    }

    EventBus.$on(EVENTS.swUpdateAvailable, this.handleSwUpdateAvailable)
  },

  beforeDestroy() {
    this.observer?.disconnect()

    EventBus.$off(EVENTS.swUpdateAvailable, this.handleSwUpdateAvailable)
  }
}
</script>

<style lang="scss" scoped>
.v-link {
  text-decoration: none;
}
</style>

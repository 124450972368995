import type {
  TextBlock,
  PageAttributes,
  SubscriberAttributes,
  PageAttribute,
  Block,
} from "./types";

import { BLOCK_TYPES, NODE_TYPES } from "./blocks";
import { isMultiLine, replaceTextInHtml } from "./text-utils";

export const TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  STRING: "string",
  RICH_TEXT: "rich_text",
} as const;

export const BLOCK_PAGE_ATTRIBUTES = {
  IMAGE_PAGE_ATTRIBUTE: "image_page_attribute_slug",
  VIDEO_PAGE_ATTRIBUTE: "video_page_attribute_slug",
  AUDIO_PAGE_ATTRIBUTE: "audio_page_attribute_slug",
  PAGE_ATTRIBUTE: "page_attribute_slug",
} as const;

export const BUILT_IN_PAGE_ATTRIBUTES = {
  TITLE: "page.title",
  FIRST_NAME: "member.first_name",
} as const;

export const getTextContent = (
  textBlock: TextBlock,
  pageAttributes: PageAttributes = {},
  subscriberAttributes: SubscriberAttributes = {}
): string => {
  const { content, page_attribute_slug } = textBlock.data;

  // If block doesn't have attribute, then setup for inline attribute
  if (!page_attribute_slug) {
    return setInlineAttributeForContent(
      content,
      pageAttributes,
      subscriberAttributes
    );
  }

  // Else, setup attribute for the block and ignore the inline attribute
  const attribute = pageAttributes[page_attribute_slug];

  if (!attribute) return content;

  const replacedValue = getValueForAttribute(attribute, subscriberAttributes);

  if (!replacedValue) return content;

  if (attribute.type === TYPES.STRING) {
    return replaceTextInHtml(content, replacedValue);
  } else if (attribute.type === TYPES.RICH_TEXT) {
    return replacedValue.startsWith("<")
      ? replacedValue
      : `<p>${replacedValue}</p>`;
  }

  return content;
};

export const setInlineAttributeForContent = (
  content: string,
  pageAttributes: PageAttributes,
  subscriberAttributes: SubscriberAttributes
): string => {
  try {
    const body = stringToHtml(content);

    if (!body) return content;

    body
      .querySelectorAll(`[data-type=${NODE_TYPES.DATA_FIELD}]`)
      .forEach((e: any) => {
        e.innerHTML = getValueForAttribute(
          pageAttributes[e.dataset.slug],
          subscriberAttributes
        );
      });

    return body.innerHTML;
  } catch {
    return content;
  }
};

const stringToHtml = (content: string): Element | null => {
  try {
    return new DOMParser()
      .parseFromString(content, "text/html")
      .documentElement.querySelector("body");
  } catch {
    return null;
  }
};

export const getValueForAttribute = (
  attribute: PageAttribute | null | undefined,
  subscriberAttributes: SubscriberAttributes
): string => {
  return attribute && isMemberAttribute(attribute)
    ? subscriberAttributes[attribute.slug] || attribute.value
    : attribute?.value || "";
};

export const isMemberAttribute = (
  attribute: PageAttribute | string | null | undefined
): boolean =>
  attribute
    ? (typeof attribute === "object" && "slug" in attribute
        ? attribute.slug
        : attribute
      ).startsWith("member.")
    : false;

export const parseToAttributeValue = (
  text: string | null,
  pageAttribute: PageAttribute
): string => {
  if (!text) return "";

  if (pageAttribute.type === TYPES.STRING) {
    return (
      new DOMParser()
        .parseFromString(text, "text/html")
        .documentElement?.querySelector("body")?.childNodes[0]?.textContent ||
      ""
    );
  } else if (pageAttribute.type === TYPES.RICH_TEXT) {
    return text.startsWith("<") ? text : `<p>${text}</p>`;
  } else {
    return "";
  }
};

export const getDefaultAttributeType = (block: Block) => {
  switch (block.type) {
    case BLOCK_TYPES.TEXT_BLOCK:
      return isMultiLine(block.data.content) ? TYPES.RICH_TEXT : TYPES.STRING;
    case BLOCK_TYPES.HERO_BLOCK:
      return TYPES.IMAGE;
    default:
      return TYPES.STRING;
  }
};

export const attributeIcon = (attributeType: string): string => {
  switch (attributeType) {
    case TYPES.STRING:
      return "format_size";

    case TYPES.RICH_TEXT:
      return "notes";

    case TYPES.IMAGE:
      return "image";

    case TYPES.VIDEO:
      return "slideshow";

    case TYPES.AUDIO:
      return "headphones";

    default:
      return "";
  }
};

import store from "@/store"

import { replaceTextInHtml, isHtml } from "@shared/text-utils"

/**
 * Adds a global $i18n method to access translations through the localisation store
 */
const Localisation = {
  install(Vue) {
    Vue.prototype.$translate = function(object, key) {
      if (!object || !key) return ""

      if (!object.i18n_keys) return object[key]
      if (!object.i18n_keys?.[key]) return object[key]

      // Handle when current locale is same as default language
      if (
        store.getters["localisation/currentLocale"] ===
        store.getters["localisation/defaultLocale"]
      ) {
        return object[key]
      }

      const translation = store.getters["localisation/getTranslation"](
        object.i18n_keys[key]
      )

      if (!translation) {
        store.dispatch("localisation/loadFromCache", {
          key: object.i18n_keys[key],
          locale: store.getters["localisation/currentLocale"]
        })
      }

      const hasTranslation = translation && translation.length > 0

      const isOriginalHtml = isHtml(object[key])
      const isTranslationHtml = hasTranslation && isHtml(translation)

      const content = translation || object[key]

      if (hasTranslation && isOriginalHtml && !isTranslationHtml) {
        return replaceTextInHtml(object[key], content)
      }

      return content
    }

    Vue.prototype.$translateArray = function(object, key, item_key) {
      return object[key].map((item, index) => {
        return {
          ...item,
          [item_key]:
            this.$translate(object, `${key}.${index}.${item_key}`) ||
            item[item_key]
        }
      })
    }
  }
}

export default Localisation

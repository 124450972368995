var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isFullscreen)?_c('div',{staticClass:"v-toolbar__wrapper",attrs:{"data-role":_vm.fixed ? 'fixed-toolbar' : 'inline-toolbar',"data-transparent-toolbar":_vm.isBgTransparent}},[_c('div',{class:[
      {
        '--transparent': _vm.isBgTransparent,
        '--dark': _vm.isDarkBackground,
        '--flat': _vm.flat,
        'p-fixed top-0 left-0': _vm.fixed
      },
      'v-toolbar'
    ],style:(_vm.useAccountStyles
        ? { backgroundColor: _vm.toolbar.background_color, color: _vm.textColor }
        : {}),attrs:{"id":"toolbar"}},[_c('div',{staticClass:"v-toolbar__content container"},[_c('div',{staticClass:"v-toolbar__left"},[(_vm.back)?_c('div',{staticClass:"v-toolbar__button",style:(_vm.useAccountStyles ? { color: _vm.textColor } : {}),attrs:{"data-cy":"back","data-role":"back"},on:{"click":_vm.attemptBack}},[_c('v-icon',{attrs:{"icon":"arrow_back","rtl-flip":""}})],1):_vm._e(),(_vm.withMenu && !_vm.isDesktop)?_c('div',{staticClass:"v-toolbar__button",style:(_vm.useAccountStyles ? { color: _vm.textColor } : {}),attrs:{"data-cy":"menu"},on:{"click":_vm.toggleSidebar}},[_c('v-icon',{attrs:{"icon":"menu"}})],1):_vm._e(),_vm._t("left"),(!_vm.hasSlotData && _vm.alignment === 'left')?[(_vm.logo)?_c('toolbar-logo',{attrs:{"logo":_vm.toolbar.logo,"fallback-text":_vm.app.name,"styles":_vm.styles}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"v-toolbar__center"},[(!_vm.hasSlotData && _vm.alignment === 'center')?[(_vm.logo)?_c('toolbar-logo',{attrs:{"logo":_vm.toolbar.logo,"fallback-text":_vm.app.name,"styles":_vm.styles}}):_vm._e()]:_vm._e(),_vm._t("center")],2),_c('div',{staticClass:"v-toolbar__right"},[_vm._t("right",null,{"textColor":_vm.textColor})],2)]),_vm._t("append")],2),(_vm.fixed && !_vm.transparent && !_vm.hideSpacer)?_c('div',{staticClass:"v-toolbar__spacer"}):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }